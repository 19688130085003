import react, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';
import '../Styles/initiativesStyle.css';
import defaultInitiative from '../Images/defaultInitiative.png';
import SubscriptionWall from './SubscriptionWall';

function Initiatives() {
    const [initiatives, setInitiatives] = useState([]);
    const [featuredInitiatives, setFeaturedInitiatives] = useState([]);
    const [exploreInitiatives, setExploreInitiatives] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState(''); 
    const [locationFilter, setLocationFilter] = useState('');
    const [categories, setCategories] = useState([]); 
    const [locations, setLocations] = useState([]);
    const [isSubscribed, setIsSubscribed] = useState(false); 

    const auth = getAuth();
    const db = getFirestore();
    const storage = getStorage();

    useEffect(() => {
        fetchInitiatives();
    }, []);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                checkSubscription(user); // pass the user explicitly
            } else {
                setIsSubscribed(false);
            }
        });
    
        return () => unsubscribe(); // cleanup on unmount
    }, []);
    

    //Fetch initiatives and display them
    const fetchInitiatives = async () => {
        try {
            const initiativesCollectionRef = collection(db, "Initiatives");
            const querySnapshot = await getDocs(initiativesCollectionRef);
            const initiativesData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setInitiatives(initiativesData);

            const featured = initiativesData.filter(initiative => initiative.isFeatured);
            const explore = initiativesData.filter(initiative => !initiative.isFeatured);

            setFeaturedInitiatives(featured);
            setExploreInitiatives(explore);

            const uniqueCategories = [
                ...new Set(initiativesData.map((initiative) => initiative.category)),
            ].filter(Boolean); // Remove empty/null categories
            const uniqueLocations = [
                ...new Set(initiativesData.map((initiative) => initiative.location)),
            ].filter(Boolean);

            setCategories(uniqueCategories);
            setLocations(uniqueLocations);
        } catch (error) {
            console.error("Error fetching initiatives:", error);
        }

    };

    const checkSubscription = async () => {
        try {
            const user = auth.currentUser;

            if (!user) {
                console.log('No user is currently signed in.');
                setIsSubscribed(false);
                return;
            }    

            const userDocRef = doc(db, "Users", user.uid);
            const userDoc = await getDoc(userDocRef);
            const userData = userDoc.data();

            if (userData.batch === 'Batch1') {
                console.log("User is in Batch1, granting access.");
                setIsSubscribed(true);
                return;
            }

            const response = await fetch('https://mmp3cpgdp3.eu-west-2.awsapprunner.com/check-any-subscription', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userEmail: user.email }),
            });

            const data = await response.json();
            setIsSubscribed(data.isSubscribedToAnyItem);
        } catch (error) {
            console.error('Error checking subscription status:', error);
            setIsSubscribed(false); // In case of an error, assume not subscribed
        }
    };

    if (!isSubscribed) {
        return <SubscriptionWall />;
    }

    const calculateProgress = (amountReceived, amountNeeded) => {
        if (amountNeeded === 0) return 0;
        return (amountReceived / amountNeeded) * 100;
    };

    const filteredInitiatives = exploreInitiatives.filter((initiative) => {
        return (
            (categoryFilter ? initiative.category === categoryFilter : true) &&
            (locationFilter ? initiative.location === locationFilter : true)
        );
    });

    return (
        <div className="initiativePageContainer">
            <div className="initiativesHeaderContainer">
                <h1>Initiatives</h1>
                <p>Initiatives are an amazing way for the community to help achieve people’s ideas together. Participating in an initiatives  cant be simpler, click on one you like, participate in it and even share it with others.</p>
            </div>
        
            <div className="initiativesContainer">
                <div className="featuredInitiatives">
                    <h3>
                        Featured <span className="styledText">{featuredInitiatives.length} Initiatives</span>
                    </h3>

                    <div className="featuredInitiativeContainer">
                        {featuredInitiatives.length > 0 ? (
                            featuredInitiatives.map((initiative) => (
                                <Link 
                                    key={initiative.id} 
                                    to={`/initiative/${initiative.id}`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div key={initiative.id} className="initiativeCard">
                                        <div className="bannerProgressContainer">
                                            <img className="bannerImage" src={initiative.image || defaultInitiative} alt={'Initiative Image'}/>
                                            <div className="progressBarContainer">
                                                <div 
                                                    className="progressBarFill"
                                                    style={{ width: `${calculateProgress(initiative.amountReceived, initiative.amountNeeded)}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                        
                                        <div className="content">
                                            <h2>{initiative.title}</h2>
                                            <p>{initiative.description}</p>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <p>No initiatives found.</p>
                        )}
                    </div>
                </div>
                <div className="exploreInitiatives">
                    <div className="exploreTitle">
                        <h3>
                            Explore <span className="styledText">{exploreInitiatives.length} Initiatives</span>
                        </h3>
                        <div className="filterContainer">
                            <label>
                                <select
                                    value={categoryFilter}
                                    onChange={(e) => setCategoryFilter(e.target.value)}
                                >
                                    <option value="">All Categories</option>
                                    {categories.map((category) => (
                                        <option key={category} value={category}>
                                            {category}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <label>
                                <select
                                    value={locationFilter}
                                    onChange={(e) => setLocationFilter(e.target.value)}
                                >
                                    <option value="">All Locations</option>
                                    {locations.map((location) => (
                                        <option key={location} value={location}>
                                            {location}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>
                    </div>
                    <div className="exploreInitiativesContainer">
                        {filteredInitiatives.length > 0 ? (
                            filteredInitiatives.map((initiative) => (
                                <Link 
                                    key={initiative.id} 
                                    to={`/initiative/${initiative.id}`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div key={initiative.id} className="initiativeCard">
                                        <div className="bannerProgressContainer">
                                            <img 
                                                className="bannerImage" 
                                                src={initiative.image || defaultInitiative} 
                                                alt={'Initiative Image'} 
                                            />
                                            <div className="progressBarContainer">
                                                <div 
                                                    className="progressBarFill"
                                                    style={{ width: `${calculateProgress(initiative.amountReceived, initiative.amountNeeded)}%` }}
                                                ></div>
                                            </div>
                                        </div>
                                        <div className="content">
                                            <h2>{initiative.title}</h2>
                                            <p>{initiative.description}</p>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <p>No initiatives to explore.</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="addInitiative">
                <Link
                    to={`/create-initiative`}
                    style={{ textDecoration: 'none' }}
                >
                    <button>+</button>
                    <p>Add Initiative</p>
                </Link>
            </div>
        </div>
    );
}

export default Initiatives;