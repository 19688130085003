import '../Styles/dashStyle.css';
import { getAuth, signOut } from "firebase/auth";
import react, { useState, useEffect } from 'react';
import { Outlet, Link, Route, Routes, useNavigate } from 'react-router-dom';

function Dashboard() {
    const [activeTab, setActiveTab] = useState('HomeTab');
    const [previousTab, setPreviousTab] = useState(null);
    const [apiExpanded, setApiExpanded] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false); 

    const auth = getAuth();
    const navigate = useNavigate();

    const handleSignOut = () => {
        signOut(auth)
            .then(() => {
                console.log("Sign out successful.");
                navigate('/login'); // Navigate to login page after sign out
            })
            .catch((error) => {
                console.log(error.message);
            });
    }

    const toggleMenu = () => {
        setMenuVisible((prev) => !prev);
    };

    return (
        <div className="dashboard">
            <div className="dashContainer">
                <div className="Nav">
                    <button className="hamburger" onClick={toggleMenu}>
                        ☰
                    </button>
                    
                    <div className={`sideBtns ${menuVisible ? 'visible' : 'hidden'}`}>
                        <h2 className="logo">CCV</h2>
                        <Link to="/home" className='navLink' onClick={() => setMenuVisible(false)}>
                            Home
                        </Link>
                        <Link to="/initiatives" className='navLink' onClick={() => setMenuVisible(false)}>
                            Initiatives
                        </Link>
                        <Link to="/magazine" className='navLink' onClick={() => setMenuVisible(false)}>
                            Magazine
                        </Link>
                        {/* <Link to="/allowances" className='navLink' onClick={() => setActiveTab('ProfileTab')}>
                            Allowances
                        </Link> */}
                        <Link to="/profile" className='navLink' onClick={() => setMenuVisible(false)}>
                            Profile
                        </Link>
                        <button className="logoutBtn" onClick={handleSignOut}>
                            Logout
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M11 16L7 12M7 12L11 8M7 12H21M16 16V17C16 17.7956 15.6839 18.5587 15.1213 19.1213C14.5587 19.6839 13.7956 20 13 20H6C5.20435 20 4.44129 19.6839 3.87868 19.1213C3.31607 18.5587 3 17.7956 3 17V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H13C13.7956 4 14.5587 4.31607 15.1213 4.87868C15.6839 5.44129 16 6.20435 16 7V8" stroke="#090516" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> */}
                        </button>
                    </div>
                </div>

                <div className="tabs">
                    <Outlet />
                </div>

                <footer>
                    <div className="upper">
                        <div className="links">
                            <h3>Quick Links</h3>

                        </div>
                        <div className="links">
                            <h3>Important Info</h3>
                            <Link to="/terms-conditions" className='navLink' style={{ textDecoration: 'none' }} onClick={() => setMenuVisible(false)}>
                                Terms & Conditions
                            </Link>
                        </div>
                        <div className="contact">
                            <h3>CCV</h3>
                            <p>113 High Street, Mountain Ash, Wales, CF45 3LN</p>
                            <p>contact@ccv-group.com</p>
                        </div>
                    </div>
                    <div className="copyright">
                        © 2025 Camelot Community Ventures. All Rights Reserved.
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Dashboard;