import './App.css';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import React, { useEffect, useState } from 'react';
import { auth } from './firebase';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import InitiativeDetails from './Components/InitiativeDetails';
import MagazineDetails from './Components/MagazineDetails';
import LearnMore from './Components/LearnMore';
import Allowanaces from './Components/Allowances';
import AddInitiative from './Components/AddInitiative';
import AddMagazine from './Components/AddMagazine';
import Home from './Components/Home';
import Profile from './Components/Profile';
import Initiatives from './Components/Initiatives';
import Magazine from './Components/Magazine';

function App() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((currentUser) => {
          setUser(currentUser);
          //if (currentUser) {
            // Navigate to /home when user logs in
            //navigate('/home');
          //}

      });

      return () => unsubscribe();
  }, []);

  return (
    <div className="App">
      <Routes>
        {/* Public Paths */}
        <Route path="/login" element={user ? <Navigate to="/home" /> : <Login />} />
        {/* <Route path="/terms-conditions" element={} /> */}

        {/* Sign in required paths */}
        {user ? (
            <Route path="/" element={<Dashboard />}>
              <Route index element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="profile" element={<Profile />} />
              <Route path="initiatives" element={<Initiatives />} />
              <Route path="allowances" element={<Allowanaces />} />
              <Route path="learn-more" element={<LearnMore />} />
              <Route path="magazine" element={<Magazine />} />
              <Route path="create-initiative" element={<AddInitiative />} />
              <Route path="create-magazine" element={<AddMagazine />} />
              <Route path="/initiative/:id" element={<InitiativeDetails />} />
              <Route path="/magazine/:id" element={<MagazineDetails />} />
            </Route>
          ) : (
            // Redirect all protected routes to login if not authenticated
            <Route path="*" element={<Navigate to="/login" />} />
          )}
      </Routes>
    </div>
  );
}

export default App;
